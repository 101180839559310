export default {
  COLOR_POINT: "#EEEEEE",
  COLOR_POINT_FILL: "rgb(255,255,255)",
  COLOR_LANDSCAPE: "#FFFFFF",
  COLOR_BORDERS: "#333333",
  COLOR_LINE: "rgb(255,255,255)",
  COLOR_SELECTED_POINT: "rgb(0,184,83)",

  // COLOR_BROWN: "rgb(139,69,19)",
  // COLOR_BLACK: "rgb(0,0,0)",
  // COLOR_BLUE: "rgb(77,109,155)",
  // COLOR_LIGHT_BLUE: "rgb(124,156,201)",
  // COLOR_WHITE: "rgb(255,255,255)",
  // COLOR_WHITEY: "rgb(240,240,240)",
  // COLOR_GREEN: "rgb(0,155,77)",
  // COLOR_TOMATO: "rgb(243,114,114)"
};
