<template>
  <section>
    <div class="map" ref="mapContainer" @click="filterOpen = false">
    </div>
    <div class="titlebar" ref="filterContainer">
      <div class="f-ui-h1 title">
        {{ $t('siteTitle') }}
      </div>
      <div class="f-ui-button filterbutton" @click="toggleFilterPanel(); selected = null">
        <span class="filterlabel">{{ $t('filter.filterProjects') }} </span><img :class="{opened: filterOpen}" class="filtericon" src="../assets/filter.svg">
      </div>
      <div class="search">
        <img src="../assets/search.svg" class="icon">
        <input type="text" ref="searchInput" class="f-p" :placeholder="$t('messages.prompt')">
        <button class="clear" v-if="place" @click="searchInput.value = ''; place = null">
          ×
        </button>
      </div>
    </div>
    <div class="langtoggle f-ui-button" @click="$i18n.locale == 'en' ? $i18n.locale = 'fr' : $i18n.locale = 'en'">
      {{$i18n.locale == 'en' ? 'fr' : 'en'}}
    </div>
    <div class="overlaycontainer">
      <div class="darkoverlay" :class="{opened: filterOpen}" @click="filterOpen = false"></div>
      <div class="filter" :class="{opened: filterOpen}" ref="filterModal">
        <div class="forehead">
          <div class="f-ui-meta">
            {{ $t('filter.filterProjects') }}
          </div>
        </div>
        <div class="controls">
          <div class="section">
            <div class="sectiontitle f-ui-h2">
              {{ $t('filter.projectType') }}
            </div>
            <div class="column-2 f-p1">
              <!-- <div><input type="checkbox" name="buffers" value="buffers" v-model="selectedCategory"><label for="buffers">{{ $t('filter.types.buffers') }}</label></div>
              <div><input type="checkbox" name="ecosystem-management" value="ecosystem-management" v-model="selectedCategory"><label for="ecosystem-management">{{ $t('filter.types.ecosystem-management') }}</label></div>
              <div><input type="checkbox" name="natural-infrastructure" value="natural-infrastructure" v-model="selectedCategory"><label for="natural-infrastructure">{{ $t('filter.types.natural-infrastructure') }}</label></div>
              <div><input type="checkbox" name="water" value="water" v-model="selectedCategory"><label for="water">{{ $t('filter.types.water') }}</label></div>
              <div><input type="checkbox" name="capacity" value="capacity" v-model="selectedCategory"><label for="capacity">{{ $t('filter.types.capacity') }}</label></div>
              <div><input type="checkbox" name="natural-asset" value="natural-asset" v-model="selectedCategory"><label for="natural-asset">{{ $t('filter.types.natural-asset') }}</label></div>
              <div><input type="checkbox" name="land-conservation" value="land-conservation" v-model="selectedCategory"><label for="land-conservation">{{ $t('filter.types.land-conservation') }}</label></div>
              <div><input type="checkbox" name="land-use" value="land-use" v-model="selectedCategory"><label for="land-use">{{ $t('filter.types.land-use') }}</label></div> -->
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('buffers')" class="checkbox" :class="{selected: selectedCategory.includes('buffers')}" @click="toggleCategory('buffers')">
                {{ $t('filter.types.buffers') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('ecosystem-management')" class="checkbox" :class="{selected: selectedCategory.includes('ecosystem-management')}" @click="toggleCategory('ecosystem-management')">
                {{ $t('filter.types.ecosystem-management') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('natural-infrastructure')" class="checkbox" :class="{selected: selectedCategory.includes('natural-infrastructure')}" @click="toggleCategory('natural-infrastructure')">
                {{ $t('filter.types.natural-infrastructure') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('water')" class="checkbox" :class="{selected: selectedCategory.includes('water')}" @click="toggleCategory('water')">
                {{ $t('filter.types.water') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('capacity')" class="checkbox" :class="{selected: selectedCategory.includes('capacity')}" @click="toggleCategory('capacity')">
                {{ $t('filter.types.capacity') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('natural-asset')" class="checkbox" :class="{selected: selectedCategory.includes('natural-asset')}" @click="toggleCategory('natural-asset')">
                {{ $t('filter.types.natural-asset') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('land-conservation')" class="checkbox" :class="{selected: selectedCategory.includes('land-conservation')}" @click="toggleCategory('land-conservation')">
                {{ $t('filter.types.land-conservation') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedCategory.includes('land-use')" class="checkbox" :class="{selected: selectedCategory.includes('land-use')}" @click="toggleCategory('land-use')">
                {{ $t('filter.types.land-use') }}
              </button>
            </div>
          </div>
          <div class="section">
            <div class="sectiontitle f-ui-h2">
              {{ $t('filter.projectScale') }}
            </div>
            <div class="column-2 f-p1">
              <!-- <div><input type="checkbox" name="community" value="community" v-model="selectedScale"><label for="community">{{ $t('filter.scales.community') }}</label></div>
              <div><input type="checkbox" name="municipal" value="municipal" v-model="selectedScale"><label for="municipal">{{ $t('filter.scales.municipal') }}</label></div>
              <div><input type="checkbox" name="watershed" value="watershed" v-model="selectedScale"><label for="watershed">{{ $t('filter.scales.watershed') }}</label></div>
              <div><input type="checkbox" name="provincial" value="provincial" v-model="selectedScale"><label for="provincial">{{ $t('filter.scales.provincial') }}</label></div>
              <div><input type="checkbox" name="regional" value="regional" v-model="selectedScale"><label for="regional">{{ $t('filter.scales.regional') }}</label></div>
              <div><input type="checkbox" name="national" value="national" v-model="selectedScale"><label for="national">{{ $t('filter.scales.national') }}</label></div> -->
              <button role="checkbox" tabindex="1" :aria-checked="selectedScale.includes('community')" class="checkbox" :class="{selected: selectedScale.includes('community')}" @click="toggleScale('community')">
                {{ $t('filter.scales.community') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedScale.includes('municipal')" class="checkbox" :class="{selected: selectedScale.includes('municipal')}" @click="toggleScale('municipal')">
                {{ $t('filter.scales.municipal') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedScale.includes('watershed')" class="checkbox" :class="{selected: selectedScale.includes('watershed')}" @click="toggleScale('watershed')">
                {{ $t('filter.scales.watershed') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedScale.includes('provincial')" class="checkbox" :class="{selected: selectedScale.includes('provincial')}" @click="toggleScale('provincial')">
                {{ $t('filter.scales.provincial') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedScale.includes('regional')" class="checkbox" :class="{selected: selectedScale.includes('regional')}" @click="toggleScale('regional')">
                {{ $t('filter.scales.regional') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedScale.includes('national')" class="checkbox" :class="{selected: selectedScale.includes('national')}" @click="toggleScale('national')">
                {{ $t('filter.scales.national') }}
              </button>
            </div>
          </div>
          <div class="section">
            <div class="sectiontitle f-ui-h2">
              {{ $t('filter.projectLanguage') }}
            </div>
            <div class="column-2 f-p1">
              <!-- <div><input type="checkbox" name="english" value="english" v-model="selectedLanguage"><label for="english">{{ $t('filter.languages.english') }}</label></div>
              <div><input type="checkbox" name="french" value="french" v-model="selectedLanguage"><label for="french">{{ $t('filter.languages.french') }}</label></div> -->
              <button role="checkbox" tabindex="1" :aria-checked="selectedLanguage.includes('english')" class="checkbox" :class="{selected: selectedLanguage.includes('english')}" v-on:click="toggleLanguage('english')">
                {{ $t('filter.languages.english') }}
              </button>
              <button role="checkbox" tabindex="1" :aria-checked="selectedLanguage.includes('french')" class="checkbox" :class="{selected: selectedLanguage.includes('french')}" @click="toggleLanguage('french')">
                {{ $t('filter.languages.french') }}
              </button>
            </div>
          </div>
        </div>
        <div class="chin">
          <div class="close f-ui-button" @click="selectedCategory = []; selectedScale = []; selectedLanguage = [];">
            {{$t('messages.reset')}}
          </div>
          <div class="button" @click="filterOpen = false; resetPan()">
            {{$t('messages.apply')}}
          </div>
        </div>
      </div>
    </div>
    <div class="infowindowcontainer" ref="infoWindowContainer">
      <div class="infowindow" v-if="selected">
        <div class="content">
          <div class="imagebg" v-if="selected.image">
            <img class="image" :src="selected.image">
          </div>
          <img class="cardtop" :src="require('@/assets/cardtop.svg')">
          <div class="pad">
            <div class="f-text-h1">
              {{selected.name}}
            </div>
            <div class="f-p1">
              {{selected.organization}}
            </div>
            <ul class="f-label">
              <li>
                {{ $t('filter.types.' + selected.type) }}
              </li>
              <li>
                {{ $t('filter.scales.' + selected.scale) }}
              </li>
            </ul>
            <div>
              <p class="f-p1" v-if="selected.description">
                <strong>{{$t('messages.about')}}:</strong> {{selected.description}}
              </p>
              <p class="f-p1" v-if="selected.lessons">
                <strong>{{$t('messages.lessonsLearned')}}:</strong> {{selected.lessons}}
              </p>
            </div>
            <div>
              <a v-if="selected.contact" class="buttonwide" :href="selected.contact">
                {{$t('messages.contact')}}
              </a>
              <div class="sl f-label" v-if="selected.socialsraw.includes('http')">
                <!-- {{selected.socialsraw}} -->
                <a v-for="link in selected.socialsraw.split('\n')" :href="link" :key="link">
                  {{link.match(/https?:\/\/(?:www\.)?(.*)\./)[1]}}
                </a>
              </div>
              <div class="sl f-label" v-else>
                {{selected.socialsraw}}
              </div>
            </div>
          </div>
        </div>
        <div class="chin">
          <div class="close f-ui-button" @click="selected = null">
            {{$t('messages.close')}}
          </div>
          <a v-if="selected.website" class="button" :href="selected.website" target="blank">
            {{$t('messages.visitWebsite')}}
          </a>
        </div>
      </div>
    </div>
    <!-- <div v-if="place">
      test
      {{place.geometry}}
    </div> -->
    <!-- <div>
      {{selectedCategory}}
      {{selectedScale}}
      {{selectedLanguage}}
      {{isFiltered()}}
    </div> -->
  </section>
</template>

<script setup>
  import { ref, defineProps, onMounted, watch, render } from "vue"
  import { Loader } from '@googlemaps/js-api-loader';
  import { mapSettings } from "@/constants/mapSettings";
  import { DefaultRenderer, MarkerClusterer } from '@googlemaps/markerclusterer'
  import pin from "../assets/pin.svg"
  import m1 from "../assets/m1.svg"

  const props = defineProps({
    listings: {
      type: Array,
      required: true
    }
  })

  const selected = ref();

  let google;
  let map;
  const listingMarkers = () => props.listings.filter(l=>l['15'] || l['38']).map(l=>listingMarker(l));
  // const filteredListingMarkers = () => listingMarkers().filter(l=>l.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
  const filteredListingMarkers = () => listingMarkers().filter(l =>
    selectedCategory.value.length ? selectedCategory.value.includes(l.type) : true
  ).filter(l =>
    selectedScale.value.length ? selectedScale.value.includes(l.scale) : true
  ).filter(l =>
    selectedLanguage.value.length ? selectedLanguage.value.includes(l.language) : true
  );
  let markerClusterer;

  const listingMarker = (listing) => {

    if(listing['1'] == 'French'){

      const posArray = listing['38'].split(', ').map(s => parseFloat(s))

      return {
        language: listing['1'].toLowerCase(),
        id: listing['id'],
        name: listing['36'],
        organization: listing['37'],
        type: listing['47'],
        scale: listing['48'],
        description: listing['43'],
        lessons: listing['44'],
        image: listing['50'].endsWith('/1') ? "" : listing['50'],
        rawimage: listing['50'],
        website: listing['42'],
        contact: listing['41'] ? listing['41'].startsWith('https') ? listing['41'] : 'mailto:' + listing['41'] : "",
        socialsraw: listing['40'],
        socials: {
          facebook: listing['40'].split('\r\n').filter(url=>url.includes('facebook'))[0],
          instagram: listing['40'].split('\r\n').filter(url=>url.includes('instagram'))[0],
          youtube: listing['40'].split('\r\n').filter(url=>url.includes('youtube'))[0],
          twitter: listing['40'].split('\r\n').filter(url=>url.includes('twitter'))[0],
        },
        position: {
          lat: posArray[0],
          lng: posArray[1]
        }
      }
    }

    const posArray = listing['15'].split(', ').map(s => parseFloat(s) + ((Math.random() > 0.5 ? 0.00002 : -0.00018) + Math.random() * 0.000018))

    return {
      language: listing['1'].toLowerCase(),
      id: listing['id'],
      name: listing['13'],
      organization: listing['14'],
      type: listing['22'],
      scale: listing['23'],
      description: listing['19'],
      lessons: listing['20'],
      image: listing['25'].endsWith('/1') ? "" : listing['25'],
      rawimage: listing['25'],
      website: listing['18'],
      contact: listing['17'] ? listing['17'].startsWith('https') ? listing['17'] : 'mailto:' + listing['17'] : "",
      socialsraw: listing['16'],
      socials: {
        facebook: listing['16'].split('\r\n').filter(url=>url.includes('facebook'))[0],
        instagram: listing['16'].split('\r\n').filter(url=>url.includes('instagram'))[0],
        youtube: listing['16'].split('\r\n').filter(url=>url.includes('youtube'))[0],
        twitter: listing['16'].split('\r\n').filter(url=>url.includes('twitter'))[0],
      },
      position: {
        lat: posArray[0],
        lng: posArray[1]
      }
    }
    
  }

  const filterOpen = ref(false);

  const toggleFilterPanel = ()=>{
    filterOpen.value = !filterOpen.value;
  }

  const selectedCategory = ref([])

  const toggleCategory = (cat)=>{
    if(selectedCategory.value.includes(cat)){
      selectedCategory.value = selectedCategory.value.filter(c=>c != cat)
    }else{
      selectedCategory.value.push(cat)
    }
  }

  const selectedScale = ref([])

  const toggleScale = (scl)=>{
    if(selectedScale.value.includes(scl)){
      selectedScale.value = selectedScale.value.filter(s=>s != scl)
    }else{
      selectedScale.value.push(scl)
    }
  }

  const selectedLanguage = ref([])

  const toggleLanguage = (lng)=>{
    if(selectedLanguage.value.includes(lng)){
      selectedLanguage.value = selectedLanguage.value.filter(l=>l != lng)
    }else{
      selectedLanguage.value.push(lng)
    }

  }

  const mapConfig = () => ({
    ...mapSettings,
    center: { lat: 53.1712436, lng: -89.9361426 },
    zoom: 4
  })

  const resetPan = ()=>{
    if(!place.value){
      map.panTo({ lat: 53.1712436, lng: -89.9361426 })
      map.setZoom(4)
    }else{
      map.panTo(place.value.geometry.location)
      map.fitBounds(place.value.geometry.viewport)
    }
  }

  const mapContainer = ref(null);
  const infoWindowContainer = ref(null);
  const searchInput = ref(null)
  const searchBox = ref(null);

  const place = ref(null);

  const loader = new Loader({
    apiKey: "AIzaSyDCceWvP4aPAUWhzXG4Z0G9cAU6QD_r2m0",
    libraries: ["places"]
  });

  class CustomRenderer extends DefaultRenderer{
    render({ count, position, markers }, stats){
        return new google.maps.Marker({
          position,
          icon: {
              url: m1,
              scaledSize: new google.maps.Size(53, 53),
          },
          label: {
              text: String(count),
              color: "rgba(255,255,255,0.9)",
              fontSize: "12px",
          },
          title: `Cluster of ${count} markers` + markers.map(m=>m.getTitle()).join(","),
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
      }
  }

  const renderMarkers = ()=>{
    if(markerClusterer){
      markerClusterer.clearMarkers()
    }
    markerClusterer = new MarkerClusterer({
      markers: filteredListingMarkers().map(l=>{

        const marker = new google.maps.Marker({
          position: l.position,
          icon: pin,
          title: l.name,
        })

        marker.addListener("click", () => {
          map.panTo(marker.getPosition());
          selected.value = l
        });

        return marker
      }),
      map,
      renderer: new CustomRenderer()
    })
  }

  const newSearch = _ => {

    searchBox.value = new google.maps.places.Autocomplete(searchInput.value, {
      types: ['geocode'],
      componentRestrictions: { country: 'can' },
    })

    searchBox.value.addListener('place_changed', () => {
      place.value = searchBox.value.getPlace()
      map.panTo(place.value.geometry.location)
      map.fitBounds(place.value.geometry.viewport)
    })
  }

  onMounted(async ()=>{

    await loader.load().then(
      (g) => {
        google = g
        map = new g.maps.Map(mapContainer.value, mapConfig())

        map.addListener("mousedown", () => {
          selected.value = null;
        })

        map.addListener("drag", () => {
          selected.value = null;
        })

        map.addListener("zoom_changed", () => {
          selected.value = null;
        })

        mapContainer.value.appendChild(infoWindowContainer.value)

        renderMarkers();

        newSearch();

      }
    ).catch(e=>{
      console.error(e)
    })
  });

  watch(filteredListingMarkers, (ov,nv)=>{
    renderMarkers()
  })

</script>


<style lang="scss" scoped>

section {
  position: relative;
  // display: grid;
  // grid-template-columns: 1fr;
  width: 100%;
  height: 100vh;
  // .overlaycontainer{
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  // }
  .darkoverlay{
    background: rgba(0,0,0,0.2);
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s opacity ease;
    &.opened{
      opacity: 1;
      pointer-events: all;
    }
  }
  .filter{
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 10;
    background: white;
    width: 600px;
    @media (max-width: 700px){
      width: 100%;
      width: 100%;
    }
    top: 10%;
    opacity: 0;
    left: 50%;
    transform: translate(-50%, 0);
    transition: 0.2s all ease;
    pointer-events: none;
    display: flex;
    max-height: 600px;
    flex-direction: column;
    // overflow-y: auto;
    .forehead{
      border-bottom: 1px solid #EAEAEA;
      padding: 16px;
    }
    .chin{
      border-radius: 0 0 16px 16px;
      background: white;
      border-top: 1px solid #EAEAEA;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
      transition: 0.2s all ease;
      .close{
        z-index: 1;
        position: relative;
        padding: 12px 16px;
        cursor: pointer;
        color: #535353;
        border-radius: 5px;
        &:hover{
          &::after {
            opacity: 1;
          }
          // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        }
        &::after {
          content: "";
          border-radius: 5px;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          opacity: 0;
          transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
      }
    }
    .column-2{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      @media (max-width: 700px){
        grid-template-columns: 1fr;
      }
      // columns: 2;
      // .selected{
      //   font-weight: bold;
      // }
    }
    .controls{
      overflow-y: auto;
      overscroll-behavior: none;
      // height: 450px;
      padding: 0 32px;
      // display: none;
      .section{
        // padding-top: 32px;
        // margin: 32px 0;
        padding: 32px 0;
        &:not(:last-child){
          border-bottom: 1px solid #EAEAEA;
        }
        .checkbox{
          color: #535353;
          background: none;
          border: 0;
          padding: 0;
          margin: 0;
          text-align: left;
          align-items: flex-start;
          display: flex;
          // margin-bottom: 14px;
          // padding-top: 2px;
          line-height: inherit;
          &:hover{
            &::before{
              opacity: 1;
            }
          }
          &:focus{
            &::before{
              // opacity: 0.8;
            }
          }
          &::before{
            opacity: 0.7;
            display: grid;
            place-items: center;
            box-sizing: border-box;
            content: "";
            width: 20px;
            height: 20px;
            background: white;
            border-radius: 6px;
            border: 2px solid #535353;
            // margin-top: -2px;
            margin-right: 8px;
          }
          &.selected{
            &::before{
              background: url('~@/assets/checked.svg');
              background-position: center;
              // background-size: 120%;
            }
          }
        }
      }
      .sectiontitle{
        margin-bottom: 32px;
      }
      .f-ui-h2{
        color: #1C5253;
      }
    }
    &.opened{
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      pointer-events: all;
      // bottom: 0;
      // background: rgba(255, 255, 255, 1);
    }
  }
  .langtoggle{

    top: 45px;
    right: 0px;

    display: grid;
    place-items: center;

    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 10px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    box-shadow: rgba(0,0,0,0.3) 0px 1px 4px -1px;
    overflow: hidden;
  }
  .titlebar{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 16px 64px;
    position: absolute;
    top: 30px;
    left: 30px;
    background: rgba(255, 255, 255, 1);
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 16px 32px;
    .search{
      position: relative;
      grid-column: span 2;
      input[type=text]{
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
        padding-left: 30px;
        border: 0;
        // margin-bottom: 5px;
        background: #EAEAEA;
        color: #535353;
      }
      .clear{
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        font-family: inherit;
        font-size: 32px;
        line-height: inherit;
        position: absolute;
        right: 10px;
      }
      .icon{
        display: block;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
      }
    }
    .title{
      // width: 300px;
      white-space: pre-wrap;
    }
    .filterbutton{
      z-index: 1;
      position: relative;
      padding: 12px 16px;
      cursor: pointer;
      color: #535353;
      border-radius: 5px;
      @media (max-width: 700px){
        span.filterlabel{
          display: none;
        }
      }
      &:hover{
        &::after {
          opacity: 1;
        }
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      }
      &::after {
        content: "";
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        opacity: 0;
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      img{
        vertical-align: middle;
        &.opened{
          transform: rotate(180deg);
        }
      }
    }
  }
  .map{
    height: 100vh;
    .infowindowcontainer{
      .infowindow{
        &::before{
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          border-top: 12px solid #f9f9f9;
          border-left: 12px solid #f9f9f9;
          border-right: 12px solid transparent;
          border-bottom: 12px solid transparent;
          box-sizing: border-box;
          top: 50%;
          left: 0;
          transform: translate(-50%, -100%) translateX(1px) rotate(-45deg);
          @media (max-width: 700px){
            display: none;
          }
        }
        border-radius: 16px;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: calc(50% + 40px);
        width: calc(50% - 40px);
        max-width: 360px;
        // height: calc(100vh - 60px);
        max-height: 600px;
        @media (max-width: 700px){
          width: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
        }
        filter: drop-shadow(0px 5px 58px rgba(0, 0, 0, 0.25));
        display: flex;
        flex-direction: column;
        .content{
          border-radius: 16px 16px 0 0;
          overflow: hidden;
          box-sizing: border-box;
          height: 100%;
          width: 100%;
          overflow-y: auto;
          overscroll-behavior: none;
          // background: linear-gradient(180deg, transparent 56px, white 57px);
          padding-top: 16px;
          .cardtop{
            display: block;
            width: 360px;
            height: 40px;
            position: relative;
            top: 1px;
          }
          .imagebg{
            width: 100%;
            height: 200px;
            background-color: #cbd2d3;
            margin-bottom: -40px;
            margin-top: -16px;
            .image{
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
            }
          }
          .pad{
            white-space: pre-wrap;
            background: white;
            padding: 22px;
          }
          .sl{
            margin-top: 16px;
            // white-space: nowrap;
            // display: flex;
            // justify-content: center;
            text-align: center;
            a{
              display: inline-block;
              margin-right: 1ch;
              color: inherit;
            }
          }
        }
        .chin{
          border-radius: 0 0 16px 16px;
          background: white;
          border-top: 1px solid #EAEAEA;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
          transition: 0.2s all ease;
          .close{
            z-index: 1;
            position: relative;
            padding: 12px 16px;
            cursor: pointer;
            color: #535353;
            border-radius: 5px;
            &:hover{
              &::after {
                opacity: 1;
              }
              // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
            }
            &::after {
              content: "";
              border-radius: 5px;
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
              opacity: 0;
              transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
          }
        }
      }
    }
  }
}

/* @media (min-width: 900px) {
  section {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 700px;
  }

} */

</style>