<template>
  <div>
    <!-- {{listings}} -->
    <ListingMap v-if="listings.length" :listings="filteredListings"></ListingMap>
    <!-- <div class="filter">
      <input v-model="term">
      <div class="categories">
        <div @click="toggleSelection(category)" :class="{selected: selected == category}" v-for="category in listings.map(l=>l.tags).filter((value, index, self)=>self.indexOf(value) === index)" :key="category">
          {{category}}
        </div>
      </div>
    </div>
    <div class="list">
      <div class="grid">
        <TransitionGroup
          name="staggered-fade"
          :css="false"
        >
          <template v-for="listing in filteredListings">
            <div v-if="listing['1'] == 'English'" :key="listing['13']" class="griditem">
              <div class="title">
                {{listing['13']}}
              </div>
              {{listing['1']}}
              {{listing.organization}}
            </div>
            <div v-else-if="listing['1'] == 'French'" :key="listing['36']" class="griditem">
              <div class="title">
                {{listing['36']}}
              </div>
              {{listing['1']}}
              {{listing.organization}}
            </div>
          </template>
        </TransitionGroup>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import ApiService from "./ApiService";

// import axios from "axios";
import ListingMap from "./components/ListingMap.vue";

const listings = ref([]);

onMounted(async () => {
  // listings.value = await axios.get("./data.json").then(res => res.data);
  listings.value = await ApiService.getListings().then(res=>res.data.entries);
});

const selected = ref('');

const toggleSelection = (category) => {
  selected.value = selected.value == category ? '' : category;
  console.log(selected.value)
}

const term = ref('');

const filteredListings = computed(() => 
  listings.value
  .filter(l=> !selected.value || l.tags == selected.value)
  .filter(l=>(l.name + " " + l.organization).toLowerCase().includes(term.value)));

</script>

<style lang="scss" scoped>

.filter{
  padding: 20px;
  .selected{
    background: rgba($color: pink, $alpha: 0.2);
  }
}

.list{
  min-height: 900px;
}

.grid{
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 20px;
}

.griditem{
  padding: 20px;
  box-sizing: border-box;
  background: rgba($color: powderblue, $alpha: 0.2);
  .title{
    font-weight: bold;
  }
}

</style>
