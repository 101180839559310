import axios from "axios";

const consumerKey = "ck_6a898b5fda432d7d493e623dfe6b493d3551f46b";
const consumerSecret = "cs_ba303613561286c305e322158e17150ac5518374";
const auth =
  "Basic " + Buffer.from(consumerKey + ":" + consumerSecret).toString("base64");

const apiClient = axios.create({
  baseURL: `https://naturecanada.ca/wp-json/gf/v2`,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: auth
  }
});

export default {
  auth,
  consumerKey,
  getListings: () => apiClient.get("forms/32/entries?paging[page_size]=200"),
};
