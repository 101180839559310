import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'

createApp(App).use(createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'fr', // set fallback locale
  messages: {
    en: {
      siteTitle: 'Nature-based\nClimate Solutions',
      messages: {
        about: 'About',
        lessonsLearned: 'Lessons learned',
        contact: 'Contact us',
        clear: 'Clear',
        close: 'Close',
        apply: 'Show results',
        reset: 'Clear all filters',
        visitWebsite: 'See website',
        prompt: 'Find your city',
      },
      filter:{
        filterProjects: "Filter projects",
        projectType: "Type of project",
        projectScale: "Project scale",
        projectLanguage: "Project language",
        types:{
          buffers: "Protecting/Restoring Buffers",
          'ecosystem-management': "Ecosystem Restoration or Management",
          'natural-infrastructure': "Natural Infrastructure",
          water: "Water/Stormwater Management",
          capacity: "Capacity Building/Engagement",
          'natural-asset': "Natural Asset Management",
          'land-conservation': "Land Conservation",
          'land-use': "Land-use Planning/Policy",
        },
        scales:{
          community: "Community",
          municipal: "Municipal",
          watershed: "Watershed",
          provincial: "Provincial",
          regional: "Regional",
          national: "National",
        },
        languages:{
          english: "English",
          french: "French",
        }
      }
    },
    fr: {
      siteTitle: 'Solutions climatiques\nbasées sur la nature',
      messages: {
        about: 'À propos ',
        lessonsLearned: 'Leçons apprises ',
        contact: 'Contactez-nous',
        clear: 'Effacer',
        close: 'Fermer',
        apply: 'Montrer les résultats',
        reset: 'Effacer tous les filtres',
        visitWebsite: 'Voir site web',
        prompt: 'Trouvez votre ville'
      },
      filter:{
        filterProjects: "Filtrer les projets",
        projectType: "Type de projet",
        projectScale: "Échelle de projet",
        projectLanguage: "Langue de projet",
        types:{
          buffers: "Protection/restauration des zones tampons",
          'ecosystem-management': "Restauration ou gestion d’écosystèmes",
          'natural-infrastructure': "Infrastructure naturelle",
          water: "Gestion des eaux pluviales",
          capacity: "Renforcement des capacites/engagement",
          'natural-asset': "Gestion des actifs naturels",
          'land-conservation': "Conservation des terres",
          'land-use': "Planification / politique d’utilisation des sols",
        },
        scales:{
          community: "Communautaire",
          municipal: "Municipal",
          watershed: "Bassin versant",
          provincial: "Provincial",
          regional: "Régional",
          national: "National",
        },
        languages:{
          english: "Anglais",
          french: "Français",
        }
      }
    }
  }
})).mount('#app')
